import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { AgentStatus } from '@/data/Agent';
import { buildFormData, httpPost } from '@/api/http';
import { CALL_CENTER } from '@/constants/endpoint';
import { useQueryClient } from '@tanstack/vue-query';
import { AGENTS_QUERY_KEY } from '@/api/useAgents';

type AgentStatusData = { status: AgentStatus } | { status: AgentStatus; comment: string };

// TODO: This store could keep the whole agent
export const useAgentStore = defineStore('agent', () => {
  const _status = ref<AgentStatus | undefined>(undefined);
  const _statusRequestPending = ref(false);
  const queryClient = useQueryClient();

  const setAgentStatus = (newStatus: AgentStatus | undefined) => {
    _status.value = newStatus;
  };

  const _agentStatus = computed(() => _status);

  const _isStatusRequestPending = computed(() => _statusRequestPending);

  // TODO: Change in the future to optimistic update
  // setTimeout to 1_500 since we update the DB(Agents) every one second
  // https://confluence.wtg-innovation.com/display/WCP/Managing+Services+on+the+Server
  // TODO: This should be separated into two distinct functions, updateMyStatus and used by supervisor updateAgentStatus(agentUuid)
  const updateAgentStatus = (agentUuid: string, status: AgentStatus, comment?: string | null) => {
    if (!isSupervisorAgentStatusUpdate(comment)) {
      _status.value = status;
      _statusRequestPending.value = true;
    }
    const data: AgentStatusData = comment == null ? { status } : { status, comment };
    return httpPost(CALL_CENTER, buildFormData(data), {
      params: {
        action: 'set_status',
        agent_uuid: agentUuid,
      },
    }).then(() =>
      setTimeout(() => {
        queryClient.refetchQueries({ queryKey: [AGENTS_QUERY_KEY] });
        if (!isSupervisorAgentStatusUpdate(comment)) _statusRequestPending.value = false;
      }, 1_500)
    );
  };

  const isSupervisorAgentStatusUpdate = (comment?: string | null) => comment === 'SUPERVISOR';

  return { _agentStatus, _isStatusRequestPending, updateAgentStatus, setAgentStatus };
});
